import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { InputLabel } from '@material-ui/core';
import clsx from 'clsx';
import { Locale } from 'ias-lib';
import { MaritimeChoice } from './type/MaritimeChoiceComponent';
import { TransportTextButton } from 'shared/src/components/Layout/Buttons/TransportTextButton/TransportTextButton';

type MultipleMaritimeChoiceProps = {
    values: MaritimeChoice[];
    toggleValue: (arg: MaritimeChoice) => void;
    label?: string;
    type?: 'full' | 'transparent';
    className?: string;
    disabled?: boolean;
};

const useStyles1 = makeStyles(() =>
    createStyles({
        toggleContainer: {
            position: 'relative',
            display: 'flex',
            height: '100%',
        },
        button_form_label: {
            position: 'absolute',
            top: '-23px',
            fontFamily: 'Lato, sans-serif',
            fontSize: '12px',
        },
    }),
);

const MultipleMaritimeChoiceComponent: React.FunctionComponent<MultipleMaritimeChoiceProps> = ({
    values,
    disabled,
    toggleValue,
    label,
    type,
    className,
}) => {
    const classes = useStyles1();
    if (!type) type = 'full';

    const isToggled = (value: MaritimeChoice) => {
        return values.find((element) => element === value);
    };

    return (
        <div className={classes.toggleContainer}>
            {label && (
                <InputLabel className={clsx(classes.button_form_label)}>
                    {label}
                </InputLabel>
            )}
            <TransportTextButton
                className={className}
                value="FCL"
                selected={isToggled('FCL') ? true : false}
                onClick={() => toggleValue('FCL')}
                disabled={disabled}
            />
            <TransportTextButton
                className={className}
                value="roulier"
                label={Locale.trans('transport.maritime.roulier')}
                selected={isToggled('roulier') ? true : false}
                onClick={() => toggleValue('roulier')}
                disabled={disabled}
            />
            <TransportTextButton
                className={className}
                value="LCL"
                selected={isToggled('LCL') ? true : false}
                onClick={() => toggleValue('LCL')}
                disabled={disabled}
            />
        </div>
    );
};
export default MultipleMaritimeChoiceComponent;
