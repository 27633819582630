import * as React from 'react';

import { Locale } from 'ias-lib';
import { Grid } from '@material-ui/core';
import { useForm } from 'react-form';
import useCurrentUser from 'shared/src/hooks/useCurrentUser';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import NameField from '../../components/Fields/NameField';
import EmailField from '../../components/Fields/EmailField';
import PhoneField from '../../components/Fields/PhoneField';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { LoaderOverlay } from '../../components';
import { ButtonBase } from 'shared/src/components/Layout/Buttons/Button/Button';
import { cleanEntry } from '../../functions/funcs';
import { useMemo } from 'react';
import SimplePage from '../../components/Layout/SimplePage';

type Props = {};

const useStyles = makeStyles(() => ({
    title: {
        fontSize: '2em',
        fontWeight: 'bolder',
        fontFamily: 'Eesti, sans-serif',
    },
    card: {
        boxShadow: 'none',
        borderRadius: '8px',
    },
    item: {
        width: '100%',
        marginTop: '15px',
        marginBottom: '0px',
    },
    item_input: {
        backgroundColor: 'white',
        borderRadius: '5px',
    },
    button: {
        color: 'white',
        borderRadius: '8px',
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none',
        },
        fontFamily: 'Eesti, sans-serif',
        padding: '24px, 12px',
    },
    selectLabel: {
        backgroundColor: 'white',
    },
}));

const UPDATE_PROFILE = gql`
    mutation editUser($id: Int, $input: UserInput!) {
        editUser(id: $id, input: $input) {
            id
            firstName
            lastName
            email
            phoneNumber
            role
        }
    }
`;

const Profile: React.FunctionComponent<Props> = () => {
    const classes = useStyles();
    const [submit, { loading: submitting }] = useMutation(UPDATE_PROFILE);
    const { enqueueSnackbar } = useSnackbar();
    const { currentUser, loading } = useCurrentUser();
    const defaultValue = useMemo(
        () => (currentUser ? cleanEntry(currentUser) : undefined),
        [currentUser],
    );
    const {
        Form,
        meta: { isSubmitting, canSubmit },
    } = useForm({
        onSubmit: async (values: any) => {
            const { firstName, lastName, email, phoneNumber } = values;
            const user = {
                firstName,
                lastName,
                email,
                phoneNumber,
            };
            submit({
                variables: {
                    //TODO remove id from here
                    id: currentUser?.id,
                    input: user,
                },
            })
                .then(() => {
                    enqueueSnackbar(Locale.trans('profile.updated'), {
                        variant: 'success',
                    });
                })
                .catch(() => {
                    enqueueSnackbar(Locale.trans('error.undefined'), {
                        variant: 'error',
                    });
                });
        },
        defaultValues: defaultValue,
    });
    return (
        <SimplePage title={Locale.trans('profile.my')}>
            <Form>
                <Grid container spacing={3}>
                    <Grid item xs={7}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <NameField
                                    className={clsx(classes.item)}
                                    inputStyle={clsx(classes.item_input)}
                                    field="lastName"
                                    type="last"
                                    required
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <NameField
                                    className={clsx(classes.item)}
                                    inputStyle={clsx(classes.item_input)}
                                    field="firstName"
                                    type="first"
                                    required
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <EmailField
                                    className={clsx(classes.item)}
                                    inputStyle={clsx(classes.item_input)}
                                    field="email"
                                    required
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <PhoneField
                                    className={clsx(classes.item)}
                                    inputStyle={clsx(classes.item_input)}
                                    field="phoneNumber"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ButtonBase
                                    variant={'contained'}
                                    color={'primary'}
                                    type={'submit'}
                                    disabled={!canSubmit}
                                    label={Locale.trans('save')}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Form>
            <LoaderOverlay loading={isSubmitting || submitting || loading} />
        </SimplePage>
    );
};

export default Profile;
