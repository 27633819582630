import * as React from "react";
import { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from "@material-ui/core";
import { Locale } from "ias-lib";
import moment from "moment";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";

import "./FilterField.scss";
import AutocompleteField from "../AutocompleteField";
import { useForm } from "react-form";
import DateInput from "../DateInput/DateInput";
import CheckBoxElement from "../CheckBoxElement";
import MultipleAutocompleteField from "../MultipleAutocompleteField";

const useStyles = makeStyles({
  fullWidth: {
    width: "100%",
  },
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
  },
  label: {
    display: "flex",
    alignItems: "center",
    fontFamily: "Lato, sans-serif",
  },
  dialog: {
    overflowY: "auto",
    "& .MuiDialog-paper": {
      borderRadius: "8px",
      boxShadow: "none",
      overflowY: "initial",
    },
  },
  title: {
    "& .MuiTypography-h6": {
      fontFamily: "Eesti, sans-serif",
    },
  },
  inputTextfield: {
    fontFamily: "Lato, sans-serif",
  },
  pointer: {
    "& input": {
      cursor: "pointer",
    },
  },
});

type Choice = {
  value: string;
  name: string;
};

export type toFilter = {
  fieldName: string;
  label?: string;
  val: string;
  choices?: Choice[];
  multiple?: boolean;
  autocomplete?: boolean;
  isDate?: boolean;
  isRange?: boolean;
};

type Props = {
  baseData: any[]; //Need to be the props one not the state one, to have the base that doesn't change
  setObject: (arg: any[]) => void;
  toFilter: toFilter[];
  isModalOpen: boolean;
  handleModalClose: () => void;
  setIsFiltered: (arg: boolean) => void;
  filteredObject: any[];
  isFiltered: boolean;
  values: any[];
  setValues: (arg: any[]) => void;
  validateButtonLabel?: string;
};

export const FilterField: React.FunctionComponent<Props> = ({
  isFiltered,
  filteredObject,
  setIsFiltered,
  baseData,
  handleModalClose,
  isModalOpen,
  setObject,
  toFilter,
  setValues,
  values,
  validateButtonLabel,
}) => {
  const classes = useStyles();

  useEffect(() => {
    setValues(
      toFilter.map((f) => {
        if (f.isDate && f.isRange) {
          return {
            to: "",
            from: "",
          };
        }
        if (f.multiple && !f.autocomplete) {
          return f.choices ? f.choices.map((choice) => choice.value) : [];
        }
        if (f.autocomplete) {
          return [];
        }
        return "";
      })
    );
  }, [toFilter]);

  const handleClickCheckbox = (
    value: string,
    index: number,
    choiceIndex: number
  ) => {
    const tmp = [...values];
    const findIndex = tmp[index].findIndex((v: string) => v === value);
    if (findIndex !== -1) {
      tmp[index].splice(findIndex, 1);
    } else {
      tmp[index].push(value);
    }
    setValues(tmp);
  };

  const handleOnChange = (
    value: any | Date,
    index: number,
    additionalKey?: string
  ) => {
    const tmp = [...values];
    if (additionalKey) {
      if (tmp[index] === "") {
        tmp[index] = {};
      }
      tmp[index][additionalKey] = value;
    } else {
      tmp[index] = value;
    }
    setValues(tmp);
  };

  const handleCancelClick = (index: number) => {
    const tmp = [...values];
    tmp[index] = "";
    setValues(tmp);
  };

  useEffect(() => {
    const filter = () => {
      const getValue = (key: string, object: any) => {
        let toReturn = "";
        if (key.includes(".")) {
          const k = key.substr(0, key.indexOf("."));
          const newKey = key.substr(key.indexOf(".") + 1);
          toReturn = getValue(newKey, object[k]);
        } else {
          if (!object) {
            return "";
          }
          if (object[key]) {
            return object[key];
          }
        }

        return toReturn;
      };

      function hasFilter() {
        let hasFilter = false;
        values.forEach((val) => {
          if (val !== "") {
            hasFilter = true;
          }
        });
        return hasFilter;
      }

      if (!hasFilter()) {
        setObject(baseData);
        return;
      }

      const valuesToReturn = baseData.filter((object: any) => {
        let toReturn = true;
        values.forEach((value: any, index: number) => {
          if (!toReturn) {
            return;
          }
          if (
            value === "" ||
            (Array.isArray(value) && value.length === 0) ||
            (typeof value === "object" &&
              !moment(value).isValid() &&
              !Array.isArray(value) &&
              !Object.values(value).find((val: any) => val !== ""))
          ) {
            //This is to allow empty filters;
            return;
          }
          let val = getValue(toFilter[index].val, object);
          if (toFilter[index].isDate) {
            if (toFilter[index].isRange) {
              const fromDate = moment(value.from).hours(0).minutes(0);
              const toDate = moment(value.to).hours(23).minutes(59);
              const date = moment(val);
              toReturn =
                toReturn && date.isAfter(fromDate) && date.isBefore(toDate);
            } else {
              toReturn =
                toReturn &&
                moment(val).format("DD/MM/YY") ===
                  moment(value).format("DD/MM/YY");
            }
          } else {
            if (typeof value === "number") {
              value = value.toString();
            }
            if (typeof val === "number") {
              val = val.toString();
            }
            if (toFilter[index].multiple) {
              toReturn = toReturn && value.find((v: string) => v === val);
            } else {
              toReturn =
                toReturn && val.toLowerCase().includes(value.toLowerCase());
            }
          }
        });
        return toReturn;
      });
      setObject(valuesToReturn);
      setIsFiltered(true);
    };
    filter();
  }, [values, baseData, toFilter, setObject]);

  const { Form } = useForm({});

  return (
    <Dialog
      open={isModalOpen}
      onClose={() => {
        handleModalClose();
      }}
      fullWidth={true}
      maxWidth={"md"}
      className={"filter-field-dialog " + classes.dialog}
    >
      <DialogTitle className={"filter-field-title " + classes.title}>
        {Locale.trans("filter")}
      </DialogTitle>
      <DialogContent className={"dialog-content"}>
        <Form>
          <Grid container spacing={3}>
            {toFilter.map((element: toFilter, index: number) => {
              return (
                <Grid item xs={12} className={"field-container-parent"}>
                  <Grid container spacing={3} className={"field-container"}>
                    <Grid item xs={2} className={classes.label}>
                      <span className={"field-label"}>
                        {Locale.trans(`filter.${toFilter[index].fieldName}`)}
                      </span>
                    </Grid>
                    <Grid item xs={7} className={"field-second-part"}>
                      <Grid container spacing={3}>
                        <Grid item xs={10} className={"field-input-container"}>
                          {element.choices && (
                            <>
                              {element.multiple ? (
                                <>
                                  {element.autocomplete ? (
                                    <MultipleAutocompleteField
                                      label={
                                        toFilter[index].label
                                          ? Locale.trans(
                                              `filter.${toFilter[index].label}`
                                            )
                                          : Locale.trans("select")
                                      }
                                      options={element.choices}
                                      onChange={(e: any) =>
                                        handleOnChange(e.target.value, index)
                                      }
                                      value={values[index]}
                                    />
                                  ) : (
                                    <Grid container spacing={3}>
                                      {element.choices.map(
                                        (choice, choiceIndex: number) => (
                                          <Grid item xs={6}>
                                            <CheckBoxElement
                                              onChange={() =>
                                                handleClickCheckbox(
                                                  choice.value,
                                                  index,
                                                  choiceIndex
                                                )
                                              }
                                              value={values[index]?.find(
                                                (val: string) =>
                                                  val === choice.value
                                              )}
                                              label={choice.name}
                                            />
                                          </Grid>
                                        )
                                      )}
                                    </Grid>
                                  )}
                                </>
                              ) : (
                                <AutocompleteField
                                  textFieldValue={values[index]}
                                  label={Locale.trans(
                                    `filter.${toFilter[index].fieldName}`
                                  )}
                                  options={element.choices}
                                  onChange={(e, newValue) =>
                                    handleOnChange(newValue.value, index)
                                  }
                                  onClear={() => handleCancelClick(index)}
                                  field={toFilter[index].fieldName}
                                />
                              )}
                            </>
                          )}

                          {element.isDate && (
                            <>
                              {element.isRange ? (
                                <div className={"date-range"}>
                                  <DateInput
                                    value={values[index]?.from}
                                    label={
                                      toFilter[index].label
                                        ? Locale.trans(
                                            `filter.${toFilter[index].label}`
                                          )
                                        : Locale.trans("filter.fromDate")
                                    }
                                    onChange={(e) =>
                                      handleOnChange(e, index, "from")
                                    }
                                  />
                                  <DateInput
                                    label={
                                      toFilter[index].label
                                        ? Locale.trans(
                                            `filter.${toFilter[index].label}`
                                          )
                                        : Locale.trans("filter.toDate")
                                    }
                                    value={values[index]?.to}
                                    onChange={(e) =>
                                      handleOnChange(e, index, "to")
                                    }
                                  />
                                </div>
                              ) : (
                                <DateInput
                                  value={values[index]}
                                  label={
                                    toFilter[index].label
                                      ? Locale.trans(
                                          `filter.${toFilter[index].label}`
                                        )
                                      : undefined
                                  }
                                  onChange={(e) => handleOnChange(e, index)}
                                />
                              )}
                            </>
                          )}
                          {!element.choices && !element.isDate && (
                            <TextField
                              variant={"outlined"}
                              className={classes.fullWidth}
                              value={values[index]}
                              type={
                                element.val.includes("date") ? "date" : "text"
                              }
                              onChange={(e) =>
                                handleOnChange(e.target.value, index)
                              }
                              InputProps={{
                                className: clsx(
                                  classes.inputTextfield,
                                  element.val.includes("date") &&
                                    classes.pointer
                                ),
                              }}
                            />
                          )}
                        </Grid>
                        {!element.choices && (
                          <Grid item xs={2}>
                            <IconButton
                              disableRipple
                              onClick={() => handleCancelClick(index)}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>

                    <Grid item xs={3} />
                  </Grid>
                </Grid>
              );
            })}
            <Grid item xs={10} />
            <Grid item xs={2} className={classes.flexEnd}>
              <Button
                onClick={handleModalClose}
                variant={"contained"}
                color={"primary"}
                style={{ color: "white" }}
                className={"filter-dialog-button " + classes.fullWidth}
                disableRipple
              >
                {validateButtonLabel ?? Locale.trans("filter")}
              </Button>
            </Grid>
          </Grid>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default FilterField;
