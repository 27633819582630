import * as React from 'react';
import {ChangeEvent} from 'react';
import {FormControl, FormHelperText, MenuItem, TextField} from "@material-ui/core";
import {useField} from "react-form";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import './SelectField.scss';

type Choice = string | {
    value: string | number;
    name: any;
    hidden?: boolean;
}
type FieldsProps = {
    className?: string;
    inputStyle?: string;
    field: string;
    fullWidth?: boolean;
    label?: string;
    required?: boolean;
    validate?: (val: any) => string | false | undefined;
    choices: Choice[];
    disabled?: boolean;
    setDefaultValue?: boolean,
    inputClass?: string;
    variant?: "standard" | "outlined" ,
    onChange?: (arg: any) => void;
    startingVal?: any;
    noDefaultValue?: boolean;
}

const useStyles = makeStyles({
    input: {
        fontFamily: 'Lato, sans-serif',
    }
});

export const SelectField: React.FunctionComponent<FieldsProps> = ({
                                                                      inputClass,
                                                                      disabled,
                                                                      setDefaultValue,
                                                                      validate,
                                                                      fullWidth,
                                                                      label,
                                                                      required,
                                                                      field,
                                                                      className,
                                                                      startingVal,
                                                                      noDefaultValue,
                                                                      inputStyle,
                                                                      choices,
                                                                      variant,
                                                                      onChange
                                                                  }) => {

    const classes = useStyles();
    const getDefault = () => {
        if (noDefaultValue) {
            return null;
        }
        if (startingVal) {
            return startingVal;
        } else {
            if (typeof choices[0] === "string") {
                return choices[0];
            } else if (typeof choices[0] === "object") {
                return choices[0].value
            }
        }
    };
    const defaultVal = getDefault();
    if (setDefaultValue === undefined) setDefaultValue = true;
    const {
        meta: {error},
        getInputProps
    } = useField(field, {
        validate: validate,
        defaultValue: setDefaultValue ? defaultVal : "",
    });
    const {onChange: propsOnChange, onBlur, value} = getInputProps();


    const onChangeValue = (event: any) => {
        if (onChange) {
            onChange(event);
        }
        propsOnChange(event as ChangeEvent);
    }

    return (
        <FormControl
            className={className + " select-field"}
            variant="outlined"
            error={!!error}
            fullWidth={fullWidth}
            required={required}
            disabled={disabled}
        >

            <TextField
                select
                label={label}

                id={`${field}-select`}
                className={clsx(inputStyle, classes.input)}
                onChange={(event: any) => onChangeValue(event)}
                onBlur={onBlur}
                value={value ?? ""}
                required={required}
                disabled={disabled}
                InputProps={{
                    classes: {
                        input: "select-field-input " + inputClass
                    }
                }}
                variant={variant ? variant : "outlined"}
            >
                {choices.filter((choice) => typeof (choice) === "string" || !choice.hidden).map((choice) => {
                    if (typeof choice === 'string') {
                        return (
                            <MenuItem key={choice} value={choice} disableRipple
                                      className={"select-field-menu-item"}>{choice}</MenuItem>
                        )
                    }
                    return (
                        <MenuItem key={choice.value} value={choice.value} disableRipple
                                  className={"select-field-menu-item"}>{choice.name}</MenuItem>
                    )
                })}
            </TextField>
            <FormHelperText>{error}</FormHelperText>
        </FormControl>
    );
};
export default SelectField;
