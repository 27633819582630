import * as React from "react";
import { TextField as BaseTextField } from "@material-ui/core";
import "./NumberInput.scss";

type FieldsProps = {
  className?: string;
  inputStyle?: string;
  field?: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  validate?: (val: any) => string | false | undefined;
  multiline?: boolean;
  rows?: number;
  onChange?: (e: any) => void;
  value?: string | number;
  maxLength?: number;
  startingValue?: number;
  decimal?: boolean;
  negative?: boolean;
  InputProps?: any;
  align?: string;
  onBlur?: any;
  error?: string | null;
  nonZero?: boolean;
  variant?: "standard" | "outlined";
  min?: number;
  max?: number;
  inputRef?: any;
  name?: string;
  fullWidth?: boolean;
  placeholder?: string;
};

export const NumberInput: React.FunctionComponent<FieldsProps> = ({
  onBlur,
  align,
  name,
  fullWidth,
  disabled,
  negative,
  value,
  decimal,
  InputProps,
  maxLength,
  onChange,
  className,
  inputStyle,
  required,
  field,
  label,
  multiline,
  rows,
  nonZero,
  variant,
  error,
  min,
  max,
  inputRef,
  placeholder,
}) => {
  React.useEffect(() => {
    if (field) {
      document
        .querySelector("input[id=" + CSS.escape(field) + "]")
        ?.addEventListener("keypress", (evt) => {
          // @ts-ignore;
          if (
            evt.which === 8 ||
            evt.which === 44 ||
            evt.which === 46 ||
            evt.which === 45
          ) {
            return;
          }
          // @ts-ignore
          if (evt.which < 48 || evt.which > 57) {
            evt.preventDefault();
          }
        });
    } else {
      document
        .querySelector("input[type='number']")
        ?.addEventListener("keypress", (evt) => {
          // @ts-ignore;
          if (
            evt.which === 8 ||
            evt.which === 44 ||
            evt.which === 46 ||
            evt.which === 45
          ) {
            return;
          }
          // @ts-ignore
          if (evt.which < 48 || evt.which > 57) {
            evt.preventDefault();
          }
        });
    }
  }, []);
  return (
    <BaseTextField
      value={value || ""}
      className={className}
      error={!!error}
      name={name}
      helperText={error}
      id={field}
      fullWidth={fullWidth}
      label={label}
      type={"number"}
      variant={variant ? variant : "outlined"}
      disabled={disabled}
      required={required}
      inputRef={inputRef}
      placeholder={placeholder}
      onBlur={onBlur}
      InputProps={{
        ...InputProps,
        classes: {
          input: inputStyle + " number-input",
        },
        inputProps: {
          min: min
            ? min
            : negative
            ? -100
            : nonZero && decimal
            ? 0.01
            : nonZero && !decimal
            ? 1
            : 0,
          max: max
            ? max
            : (maxLength && Math.pow(maxLength, maxLength + 1) - 1) || null,
          step: decimal ? 0.01 : 1,
          style: { textAlign: align ? align : "left" },
        },
      }}
      multiline={multiline}
      rows={rows}
      onChange={(e) => onChange && onChange(e)}
    />
  );
};
export default NumberInput;
