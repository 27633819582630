import * as React from 'react';
import { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { Locale } from 'ias-lib';
import { makeStyles } from '@material-ui/core/styles';
import SwitchField from '../../components/Fields/SwitchField';
import { useForm } from 'react-form';
import {
    CLIENT,
    PARTNER,
    useIsGranted,
} from 'shared/src/services/useIsGranted';
import './notifications.scss';
import { useMutation, useQuery } from '@apollo/client';
import {
    EDIT_NOTIFICATION_CONFIGURATION,
    GET_NOTIFICATION_CONFIGURATION,
} from './gql';
import LoaderOverlay from 'shared/src/components/LoaderOverlay/LoaderOverlay';
import { useSnackbar } from 'notistack';
import { cleanEntry } from '../../functions/funcs';
import ErrorView from 'shared/src/components/ErrorView';
import { Logger } from 'shared/src/services/Logger';
import SimplePage from '../../components/Layout/SimplePage';
import { ButtonBase } from 'shared/src/components/Layout/Buttons/Button/Button';

const useStyles = makeStyles({
    title: {
        fontSize: '2em',
        fontWeight: 'bolder',
        fontFamily: 'Eesti, sans-serif',
    },
    switches: {
        display: 'flex',
        flexDirection: 'column',
        color: 'grey',
    },
    switch: {
        marginBottom: '15px',
    },
    buttonStyle: {
        color: 'white',
        width: '200px',
        fontWeight: 'bolder',
        borderRadius: '8px',
        boxShadow: 'none',
    },
    card: {
        boxShadow: 'none',
        borderRadius: '8px',
    },
});

type Props = {
    notificationConfiguration: NotificationConfiguration;
};

const Notifications: React.FunctionComponent<Props> = ({
    notificationConfiguration,
}) => {
    const classes = useStyles();
    const { isGranted } = useIsGranted();
    const { enqueueSnackbar } = useSnackbar();
    const { Form } = useForm({
        onSubmit: async (values: any) => {
            const {
                availableQuotation,
                availableOffer,
                declinedOffer,
                cancellation,
                bookingToValidate,
                BLToValidate,
                scheduledTransport,
                newMessage,
                billToPay,
                newCorrespondingQuotationRequest,
                offerAccepted,
                accessToExpeditionUnlocked,
                emailNotification,
            } = values;
            const input = {
                availableQuotation,
                availableOffer,
                declinedOffer,
                cancellation,
                bookingToValidate,
                BLToValidate,
                scheduledTransport,
                newMessage,
                billToPay,
                newCorrespondingQuotationRequest,
                offerAccepted,
                accessToExpeditionUnlocked,
                emailNotification,
            };
            editConfiguration({
                variables: {
                    input: input,
                },
            })
                .then(() => {
                    enqueueSnackbar(
                        Locale.trans('notificationConfiguration.edit.success'),
                        { variant: 'success' },
                    );
                })
                .catch(() => {
                    enqueueSnackbar(
                        Locale.trans('notificationConfiguration.edit.error'),
                        { variant: 'error' },
                    );
                });
            //onSubmit(values);
        },
        defaultValues: useMemo(() => cleanEntry(notificationConfiguration), [
            notificationConfiguration,
        ]),
    });

    const [editConfiguration, { loading }] = useMutation(
        EDIT_NOTIFICATION_CONFIGURATION,
    );

    if (loading) {
        return <LoaderOverlay loading={loading} />;
    }

    return (
        <SimplePage title={Locale.trans('notifications.my')}>
            <Form>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div className={classes.switches}>
                            {isGranted(CLIENT) ? (
                                <>
                                    <SwitchField
                                        field={'availableQuotation'}
                                        label={Locale.trans(
                                            'notifications.availableQuotation',
                                        )}
                                        className={classes.switch}
                                    />
                                    <SwitchField
                                        field={'availableOffer'}
                                        label={Locale.trans(
                                            'notifications.availableOffer',
                                        )}
                                        className={classes.switch}
                                    />
                                    <SwitchField
                                        field={'declinedOffer'}
                                        label={Locale.trans(
                                            'notifications.declinedOffer',
                                        )}
                                        className={classes.switch}
                                    />
                                    <SwitchField
                                        field={'cancellation'}
                                        label={Locale.trans(
                                            'notifications.cancellation',
                                        )}
                                        className={classes.switch}
                                    />
                                    <SwitchField
                                        field={'BLToValidate'}
                                        label={Locale.trans(
                                            'notifications.BLToValidate',
                                        )}
                                        className={classes.switch}
                                    />
                                    <SwitchField
                                        field={'scheduledTransport'}
                                        label={Locale.trans(
                                            'notifications.scheduledTransport',
                                        )}
                                        className={classes.switch}
                                    />
                                    <SwitchField
                                        field={'newMessage'}
                                        label={Locale.trans(
                                            'notifications.newMessage',
                                        )}
                                        className={classes.switch}
                                    />
                                    <SwitchField
                                        field={'billToPay'}
                                        label={Locale.trans(
                                            'notifications.billToPay',
                                        )}
                                        className={classes.switch}
                                    />
                                </>
                            ) : isGranted(PARTNER) ? (
                                <>
                                    <SwitchField
                                        field={
                                            'newCorrespondingQuotationRequest'
                                        }
                                        label={Locale.trans(
                                            'notifications.newRequest',
                                        )}
                                        className={classes.switch}
                                    />
                                    <SwitchField
                                        field={'declinedOffer'}
                                        label={Locale.trans(
                                            'notifications.declinedOfferByClient',
                                        )}
                                        className={classes.switch}
                                    />
                                    <SwitchField
                                        field={'accessToExpeditionUnlocked'}
                                        label={Locale.trans(
                                            'notifications.accessToExpeditionUnlocked',
                                        )}
                                        className={classes.switch}
                                    />
                                    <SwitchField
                                        field={'offerAccepted'}
                                        label={Locale.trans(
                                            'notifications.acceptedOfferByClient',
                                        )}
                                        className={classes.switch}
                                    />
                                    <SwitchField
                                        field={'newMessage'}
                                        label={Locale.trans(
                                            'notifications.newClientMessage',
                                        )}
                                        className={classes.switch}
                                    />
                                    <SwitchField
                                        field={'cancellation'}
                                        label={Locale.trans(
                                            'notifications.cancellation',
                                        )}
                                        className={classes.switch}
                                    />
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonBase
                            variant={'contained'}
                            color={'primary'}
                            type={'submit'}
                            label={Locale.trans('save')}
                        />
                    </Grid>
                </Grid>
            </Form>
        </SimplePage>
    );
};

const Wrapper: React.FunctionComponent = () => {
    const { data, error, loading, refetch } = useQuery(
        GET_NOTIFICATION_CONFIGURATION,
        {
            fetchPolicy: 'cache-and-network',
        },
    );

    if (loading) {
        return <LoaderOverlay loading={loading} />;
    }
    if (error) {
        Logger.logError(error);
        return <ErrorView refetch={refetch} />;
    }

    return (
        <Notifications
            notificationConfiguration={data?.notificationConfiguration || []}
        />
    );
};

export default Wrapper;
