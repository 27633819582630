import { lazy } from 'react';
import useCurrentUser from 'shared/src/hooks/useCurrentUser';
import { PARTNER, useIsGranted } from 'shared/src/services/useIsGranted';

const Home = lazy(() => import('views/partner/Home/Home'));
const Requests = lazy(() => import('views/partner/requests/Main'));
const Offers = lazy(() => import('views/partner/offers/Main'));
const Expeditions = lazy(() => import('views/partner/expeditions/Main'));
const Bills = lazy(() => import('views/partner/bills/Main'));

const ErrorCompanyArchived = lazy(() => import('views/error/CompanyArchived'));
const ErrorCompanyBlocked = lazy(() => import('views/error/CompanyBlocked'));
const CompanyPending = lazy(() => import('views/error/CompanyPending'));

type T_ROUTE = {
    exact?: boolean;
    component: any;
    path?: string;
};

export function usePartnerRoutes(): T_ROUTE[] {
    const { loading, isGranted } = useIsGranted();
    const { currentUser } = useCurrentUser();
    const companyStatus = currentUser?.company?.status;

    if (loading || !isGranted(PARTNER)) {
        return [];
    }

    switch (companyStatus) {
        case 'active':
            return [
                {
                    exact: true,
                    path: '/',
                    component: Home,
                },
                {
                    path: '/requests',
                    component: Requests,
                },
                {
                    path: '/offers',
                    component: Offers,
                },
                {
                    path: '/expeditions',
                    component: Expeditions,
                },
                {
                    path: '/bills',
                    component: Bills,
                },
            ];
        case 'blocked':
            return [
                {
                    component: Bills,
                    exact: true,
                    path: '/bills',
                },
                {
                    component: ErrorCompanyBlocked,
                },
            ];
        case 'pending':
            return [
                {
                    component: CompanyPending,
                },
            ];
        default:
            return [
                {
                    component: ErrorCompanyArchived,
                },
            ];
    }
}
