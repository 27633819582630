import * as React from 'react';
import { useForm } from 'react-form';
import { Grid } from '@material-ui/core';
import { Locale } from 'ias-lib';
import { TextField } from 'shared/src/components/Fields';
import AddressField from '../../../components/CompoundFields/AddressField';
import NameField from '../../../components/Fields/NameField';
import PhoneField from '../../../components/Fields/PhoneField';
import { cleanEntry } from '../../../functions/funcs';
import { useMemo } from 'react';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { ButtonBase } from 'shared/src/components/Layout/Buttons/Button/Button';
import SimplePage from '../../../components/Layout/SimplePage';
import {
    ROLE_HANDLE_COMPANY_INFORMATIONS,
    useIsGranted,
} from 'shared/src/services/useIsGranted';

const GET_CURRENT = gql`
    {
        currentClient {
            id
            notificationEmail
            businessName
            lastNameBillingContact
            firstNameBillingContact
            phoneBillingContact
            billingAddress {
                address
                compAddress
                city
                postalCode
                country
            }
        }
    }
`;
const EDIT_CURRENT = gql`
    mutation editClient($input: ClientInput!) {
        editClient(input: $input) {
            id
            notificationEmail
            businessName
            lastNameBillingContact
            firstNameBillingContact
            phoneBillingContact
            billingAddress {
                address
                compAddress
                city
                postalCode
                country
            }
        }
    }
`;

const ClientSettings: React.FunctionComponent = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { data } = useQuery(GET_CURRENT, {
        pollInterval: undefined,
        fetchPolicy: 'cache-and-network',
    });
    const [editClient] = useMutation(EDIT_CURRENT);
    const { isGranted } = useIsGranted();
    const disabled = !isGranted(ROLE_HANDLE_COMPANY_INFORMATIONS);

    const defaultValues = useMemo(() => cleanEntry(data?.currentClient || {}), [
        data,
    ]);
    const { Form } = useForm({
        onSubmit: async (values: any) => {
            try {
                await editClient({
                    variables: { input: values },
                });
                enqueueSnackbar(Locale.trans('profile.updated.settings'), {
                    variant: 'success',
                });
            } catch (e) {
                enqueueSnackbar(Locale.trans('error.undefined'), {
                    variant: 'error',
                });
            }
        },
        defaultValues,
    });

    return (
        <SimplePage title={Locale.trans('billingSettings')}>
            <Form>
                <Grid container spacing={3}>
                    <Grid item xs={8}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    className={'formItem'}
                                    label={Locale.trans(
                                        'billingSettings.notificationMail',
                                    )}
                                    field="notificationEmail"
                                    disabled={disabled}
                                    required={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    className={'formItem'}
                                    label={Locale.trans(
                                        'billingSettings.companyName',
                                    )}
                                    field="businessName"
                                    disabled={disabled}
                                    required={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AddressField
                                    streetField={'billingAddress.address'}
                                    compAddressField={
                                        'billingAddress.compAddress'
                                    }
                                    cityField={'billingAddress.city'}
                                    postalCodeField={
                                        'billingAddress.postalCode'
                                    }
                                    countryField={'billingAddress.country'}
                                    cityFreeText={true}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <span className={'sub-title'}>
                                    {Locale.trans('billingSettings.contact')}
                                </span>
                            </Grid>
                            <Grid item xs={6}>
                                <NameField
                                    field={'lastNameBillingContact'}
                                    type={'last'}
                                    className={'formItem'}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <NameField
                                    field={'firstNameBillingContact'}
                                    type={'first'}
                                    className={'formItem'}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <PhoneField
                                    field={'phoneBillingContact'}
                                    className={'formItem'}
                                    disabled={disabled}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} />
                    <Grid item xs={12}>
                        <ButtonBase
                            variant={'contained'}
                            color={'primary'}
                            type={'submit'}
                            disabled={disabled}
                            label={Locale.trans('save')}
                        />
                    </Grid>
                </Grid>
            </Form>
        </SimplePage>
    );
};

export default ClientSettings;
