import moment from "moment";
import { Locale } from "ias-lib";

const range = (start: number, stop?: number, step?: number) => {
  if (typeof stop == "undefined") {
    // one param defined
    stop = start;
    start = 0;
  }

  if (typeof step == "undefined") {
    step = 1;
  }

  if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
    return [];
  }

  var result = [];
  for (var i = start; step > 0 ? i < stop : i > stop; i += step) {
    result.push(i);
  }

  return result;
};

const canShowTransport = (transport: TransportData, booking: Booking) => {
  if (transport && booking) {
    return !["new", "accepted", "waitingValidation"].includes(booking.status);
  }
  return false;
};

const canRenewOffer = (offer: Offer) => {
  return (
    offer.status === "expired" &&
    offer.quotation.status !== "expired" &&
    offer.quotation.status !== "canceled" &&
    offer.quotation.status !== "refused"
  );
};

const getPriceDetails = () => {
  return [
    "portDeliveryPrice",
    "destinationCustomsDeclarationPrice",
    "originTHCPrice",
    "transportPrice",
    "documentsPrice",
    "originCustomsDeclarationPrice",
    "destinationTHCPrice",
    "destinationDeliveryPrice",
    "transportSurcharges",
    "customTaxes",
    "tva",
    "insurancePrice",
    "originMiscellaneousCost",
    "destinationMiscellaneousCost",
    "other",
  ];
};

export const getColor = (color: string) => {
  switch (color) {
    case "purple":
      return "#9548FF";
    case "orange":
      return "#EB8724";
    case "green":
      return "#5AB320";
    case "blue":
      return "#3F5CD1";
    case "brown":
      return "#A76523";
    case "yellow":
      return "#E9CC0F";
    case "red":
      return "#B31723";
    case "black":
      return "#101E2C";
  }
};

const canCancelOffer = (offer: Offer) => {
  return (
    (offer.status === "inProgress" || offer.status === "toConfirm") &&
    moment(offer.expireAt).isAfter(moment(), "day")
  );
};

export {
  range,
  canShowTransport,
  canRenewOffer,
  getPriceDetails,
  canCancelOffer,
};

export const stringToObjectVar = (value: string) => {
  const splittedString = value.split(",");
  const variables: any = {};
  splittedString.forEach((keyValue: string) => {
    const key = keyValue.split("=")[0];
    const value = keyValue.split("=")[1];
    if (key === "DOCUMENT_TYPE") {
      variables[key] = Locale.trans("documents.types." + value);
    } else {
      variables[key] = value;
    }
  });
  return variables;
};

export const getTranslationFromEvent = (event: AppEvent) => {
  return event.message.split("#")[0];
};
export const getTranslationVariableFromEvent = (event: AppEvent) => {
  const splittedMessage = event.message.split("#");
  const variables: any = {};
  const translationVar = splittedMessage.length > 1 ? splittedMessage[1] : null;
  if (translationVar) {
    const splittedString = translationVar.split(",");
    splittedString.forEach((keyValue: string) => {
      const key = keyValue.split("=")[0];
      const value = keyValue.split("=")[1];
      if (key === "DOCUMENT_TYPE") {
        variables[key] = Locale.trans("documents.types." + value);
      } else if (key === "WORKFLOW_STEP") {
        variables[key] = Locale.trans("bookings.list." + value);
      } else if (key.includes("BOOKING_STATUS")) {
        variables[key] = Locale.trans("bookings.state." + value);
      } else if (key.includes("COMPANY_STATUS")) {
        variables[key] = Locale.trans("status." + value).toUpperCase();
      } else if (key.includes("QUOTATION_STATUS")) {
        variables[key] = Locale.trans("quotations.status." + value);
      } else if (key.includes("TRANSPORT_STATUS")) {
        variables[key] = Locale.trans("transport.state." + value);
      } else if (key.includes("EXPEDITION_STATUS")) {
        variables[key] = Locale.trans("expeditions.status." + value);
      } else if (key === "REMINDER_STEP") {
        variables[key] = Locale.trans("reminders.step." + value);
      } else {
        variables[key] = value;
      }
    });
  }
  if (event.createdBy) {
    variables["USER"] =
      event.createdBy.firstName + " " + event.createdBy.lastName;
  } else {
    variables["USER"] = "INTTRA";
  }
  variables["CREATED_AT"] = moment(event.createdAt).format("DD/MM/YYYY");
  return variables;
};

export const EVENT_TYPE_IN_MESSAGE_THREAD = [
  "inttra",
  "actionOnDocument",
  "modifiedInstructions",
  "booking",
  "quotationCanceled",
  "bookingCanceled",
  "offerCanceled",
  "billDeposit",
  "submittingOffer",
  "confirmingOffer",
  "bookingAutoValidated",
  "manualStatusChange",
  "transferToMarketplace",
  "workflowStepCompleted",
  "providerQuotationRequest",
  "providerBookingRequest",
  "validatedRefusedInstructions",
  "taskCompletion",
];

export const DOCUMENT_TYPES = {
  m2r: [
    "awb",
    "billOfLading",
    "billOfLadingCorrect",
    "certificate",
    "cim",
    "weightDeclaration",
    "customDeclaration",
    "doCro",
    "awbDraft",
    "billOfLadingDraft",
    "imdgImo",
    "vgm",
    "other",
  ],
  partner: [
    "awb",
    "billOfLading",
    "certificate",
    "cim",
    "customDeclaration",
    "awbDraft",
    "billOfLadingDraft",
    "form",
    "imdgImo",
    "vgm",
    "other",
  ],
  client: [
    "billOfLadingAwb",
    "certificate",
    "customDeclaration",
    "commercialBill",
    "weightDeclaration",
    "imdgImo",
    "paymentProof",
    "license",
    "packingList",
    "vgm",
    "other",
  ],
};
