import * as React from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import MomentLocaleUtils from "react-day-picker/moment";
import "react-day-picker/lib/style.css";
import { Locale } from "ias-lib";
import moment from "moment";

import "./DateInput.scss";
import { InputAdornment, TextField } from "@material-ui/core";
import EventIcon from "@material-ui/icons/Event";
import clsx from "clsx";

type FieldsProps = {
  value: string;
  onChange: (day: Date) => void;
  label?: string;
  required?: boolean;
};

const { formatDate, parseDate } = MomentLocaleUtils;

const DateInput: React.FunctionComponent<FieldsProps> = ({
  value,
  onChange,
  required,
  label,
}) => {
  //TODO get locale
  const localization = "fr";
  //TODO change format depending on locale
  const format = "DD/MM/YYYY";

  let placeholder = label ? label : Locale.trans("selectDate");
  if (required) {
    placeholder += " *";
  }

  return (
    <div className="day-picker">
      <DayPickerInput
        classNames={{
          container: "DayPickerInput",
          overlayWrapper: "DayPickerInput-OverlayWrapper",
          overlay: "DayPickerInput-Overlay",
        }}
        inputProps={{
          required,
        }}
        dayPickerProps={{
          locale: localization,
          localeUtils: MomentLocaleUtils,
        }}
        onDayChange={(date) => onChange(date)}
        formatDate={formatDate}
        parseDate={parseDate}
        format={format}
        placeholder={placeholder}
        value={value ? moment(value).format(format) : ""}
        component={(inputProps: any) => {
          return (
            <TextField
              {...inputProps}
              onClick={() => {
                inputProps.onClick();
              }}
              variant={"outlined"}
              fullWidth={true}
              label={label}
              InputProps={{
                style: { fontFamily: "Lato, sans-serif" },
                endAdornment: (
                  <InputAdornment position="end">
                    <EventIcon style={{ color: "#757575" }} />
                  </InputAdornment>
                ),
              }}
            />
          );
        }}
      />
    </div>
  );
};
export default DateInput;
