import * as React from 'react';
import { Grid } from '@material-ui/core';
import {
    useIsGranted,
    CLIENT,
    PARTNER,
} from 'shared/src/services/useIsGranted';
import PartnerSettings from './PartnerSettings';
import ClientSettings from './ClientSettings';

import './settings.scss';

type Props = {};

const Settings: React.FunctionComponent<Props> = () => {
    const { isGranted } = useIsGranted();

    return (
        <Grid container spacing={3} className={'client-settings'}>
            <Grid item xs={12}>
                {isGranted(PARTNER) ? (
                    <PartnerSettings />
                ) : isGranted(CLIENT) ? (
                    <ClientSettings />
                ) : (
                    <></>
                )}
            </Grid>
        </Grid>
    );
};

export default Settings;
