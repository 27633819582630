import * as React from "react";
import { Button } from "@material-ui/core";
import "./TransportTextButton.scss";

type Props = {
  value: string;
  selected: boolean;
  onClick: (event: any) => void;
  className?: string;
  label?: string;
  disabled?: boolean;
};

export const TransportTextButton: React.FunctionComponent<Props> = ({
  value,
  selected,
  onClick,
  className,
  label,
  disabled,
}) => {
  return (
    <Button
      className={`movement-choice-button ${selected ? "selected" : ""} ${
        className ? className : ""
      }`}
      variant="outlined"
      value={value}
      disableRipple
      disableElevation
      disableFocusRipple
      onClick={onClick}
      disabled={disabled}
    >
      {label ? label : value}
    </Button>
  );
};
