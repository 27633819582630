import * as React from 'react';

import { Locale } from 'ias-lib';
import useCurrentUser from 'shared/src/hooks/useCurrentUser';
import {
    useIsGranted,
    PARTNER,
    CLIENT,
} from 'shared/src/services/useIsGranted';
import PartnerCompany from './PartnerCompany';
import ClientCompany from './ClientCompany';
import SimplePage from '../../../components/Layout/SimplePage';

type Props = {};

const Company: React.FunctionComponent<Props> = () => {
    const { isGranted } = useIsGranted();
    const { currentUser } = useCurrentUser();
    const userId = currentUser ? currentUser.company.id : undefined;
    return (
        <SimplePage title={Locale.trans('company.title')}>
            {userId && isGranted(PARTNER) && <PartnerCompany id={userId} />}
            {userId && isGranted(CLIENT) && <ClientCompany id={userId} />}
        </SimplePage>
    );
};

export default Company;
