import * as React from 'react';
import { Locale } from 'ias-lib';
import './EmailField.scss';
import { TextField } from 'shared/src/components/Fields';

type FieldsProps = {
    className?: string;
    inputStyle?: string;
    field?: string;
    required?: boolean;
    autocomplete?: 'off' | 'chrome-off';
    disabled?: boolean;
};

const EmailField: React.FunctionComponent<FieldsProps> = ({
    disabled,
    autocomplete,
    className,
    inputStyle,
    required,
    field,
}) => {
    field = field || 'email';

    return (
        <TextField
            fullWidth
            label={Locale.trans('email')}
            className={className + ' email-field'}
            variant={'outlined'}
            required={required}
            field={field}
            inputStyle={inputStyle}
            autocomplete={autocomplete}
            disabled={disabled}
            pattern={
                '([\\.a-zA-Z0-9_\\-])+@([a-zA-Z0-9_\\-])+(([a-zA-Z0-9_\\-])*\\.([a-zA-Z0-9_\\-])+)+'
            }
        />
    );
};
export default EmailField;
