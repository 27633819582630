import useCurrentUser from "shared/src/hooks/useCurrentUser";
import { useSnackbar } from "notistack";
import { Locale } from "ias-lib";
import { useEffect } from "react";
import Parameters from "parameters";
import { useLogout } from "shared/src/contexts/AuthContext";
import { useHistory } from "react-router-dom";

function useIsGranted(debug = false) {
  const { currentUser, loading, error } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const logout = useLogout();
  const history = useHistory();

  let isGranted = (role: string) => {
    if (!error && !loading && currentUser !== null) {
      if (currentUser.roles.indexOf(role) !== -1) {
        return true;
      }
      if (Parameters.env === "dev" && debug) {
        console.debug(`Role not found: ${role} in ${currentUser.roles}`);
      }
    }
    return false;
  };

  useEffect(() => {
    if (error) {
      if (error.message.includes("401")) {
        logout();
        history.push("/login");
      } else {
        enqueueSnackbar(Locale.trans("error.current_user"), {
          variant: "error",
        });
      }
    }
  }, [error, enqueueSnackbar, history, logout]);

  useEffect(() => {
    if (!currentUser || currentUser?.company.type === "m2r") {
      return;
    }
    // @ts-ignore
    const $crispVar = $crisp;
    if ($crispVar && currentUser) {
      $crispVar.push(["set", "user:company", [currentUser.company.name]]);
      $crispVar.push([
        "set",
        "user:nickname",
        [currentUser.firstName + " " + currentUser.lastName],
      ]);
      $crispVar.push(["set", "user:email", [currentUser.email]]);
      $crispVar.push(["set", "user:phone", [currentUser.phoneNumber]]);
    }
  }, [currentUser]);

  return {
    loading,
    isGranted,
    currentUser,
  };
}

const CLIENT = "COMPANY_CLIENT";
const PARTNER = "COMPANY_PARTNER";
const ADMIN = "COMPANY_ADMIN";
const DREAL = "COMPANY_DREAL";
const ADMIN_EMPLOYEE = "manager";
const READER_EMPLOYEE = "reader";
const EDITOR_EMPLOYEE = "editor";
const COWORKER_EMPLOYEE = "coworker";

const ROLE_ACCESS_BILL_TAB = "accessBillTab";
const ROLE_SEND_MESSAGES = "sendMessages";
const ROLE_HANDLE_INSTRUCTIONS = "handleInstructions";
const ROLE_VALIDATE_REFUSE_DOCUMENTS = "validateRefuseDocuments";
const ROLE_HANDLE_COMPANY_CONTACTS = "handleCompanyContacts";
const ROLE_HANDLE_USERS = "handleUsers";
const ROLE_MODIFY_COMPANY = "modifyCompany";
const ROLE_HANDLE_OFFERS = "handleOffers";
const ROLE_HANDLE_EXPEDITIONS = "handleExpeditions";
const ROLE_CANCEL_QUOTATIONS = "cancelQuotations";

const ROLE_USER_MANAGE = "userManagement";
const ROLE_DREAL = "dreal";
const ROLE_TRANSFERT_MARKETPLACE = "decision";
const ROLE_ACCEPT_PARTNER = "acceptPartner";
const ROLE_BLOCK_PARTNER = "blockPartner";
const ROLE_CLOSE_PARTNER = "closePartner";
const ROLE_CREATE_PROVIDER = "createProvider";
const ROLE_CLOSE_PROVIDER = "closeProvider";
const ROLE_UPDATE_PROVIDER = "modifyProvider";
const ROLE_DASHBOARD = "dashboard";
const ROLE_CONFIRM_BOOKING = "confirmBooking";
const ROLE_DELETE_BOOKING = "deleteBooking";
const ROLE_SEND_QUOTATION = "sendQuotation";
const ROLE_DELETE_QUOTATION = "deleteQuotation";
const ROLE_ACCEPT_CLIENT = "acceptClient";
const ROLE_BLOCK_CLIENT = "blockClient";
const ROLE_CLOSE_CLIENT = "closeClient";
const ROLE_ACCESS_CLIENT_PAYMENTS = "accessClientPayments";
const ROLE_MODIFY_CLIENT_PAYMENTS = "modifyClientPayments";
const ROLE_ACCESS_PARTNER_PAYMENTS = "accessPartnerPayments";
const ROLE_MODIFY_PARTNER_PAYMENTS = "modifyPartnerPayments";
const ROLE_HANDLE_COMPANY_INFORMATIONS = "handleCompanyInformations";

export {
  useIsGranted,
  CLIENT,
  PARTNER,
  ADMIN,
  DREAL,
  ADMIN_EMPLOYEE,
  READER_EMPLOYEE,
  EDITOR_EMPLOYEE,
  COWORKER_EMPLOYEE,
  ROLE_ACCESS_BILL_TAB,
  ROLE_SEND_MESSAGES,
  ROLE_HANDLE_INSTRUCTIONS,
  ROLE_VALIDATE_REFUSE_DOCUMENTS,
  ROLE_HANDLE_COMPANY_CONTACTS,
  ROLE_HANDLE_USERS,
  ROLE_MODIFY_COMPANY,
  ROLE_HANDLE_OFFERS,
  ROLE_HANDLE_EXPEDITIONS,
  ROLE_USER_MANAGE,
  ROLE_DREAL,
  ROLE_TRANSFERT_MARKETPLACE,
  ROLE_ACCEPT_PARTNER,
  ROLE_BLOCK_PARTNER,
  ROLE_CLOSE_PARTNER,
  ROLE_CREATE_PROVIDER,
  ROLE_CLOSE_PROVIDER,
  ROLE_UPDATE_PROVIDER,
  ROLE_DASHBOARD,
  ROLE_CONFIRM_BOOKING,
  ROLE_DELETE_BOOKING,
  ROLE_SEND_QUOTATION,
  ROLE_DELETE_QUOTATION,
  ROLE_ACCEPT_CLIENT,
  ROLE_BLOCK_CLIENT,
  ROLE_CLOSE_CLIENT,
  ROLE_ACCESS_CLIENT_PAYMENTS,
  ROLE_MODIFY_CLIENT_PAYMENTS,
  ROLE_ACCESS_PARTNER_PAYMENTS,
  ROLE_MODIFY_PARTNER_PAYMENTS,
  ROLE_CANCEL_QUOTATIONS,
  ROLE_HANDLE_COMPANY_INFORMATIONS,
};
