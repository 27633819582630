import * as React from "react";
import { MenuItem, Tooltip } from "@material-ui/core";
import moment from "moment";
import { Locale } from "ias-lib";
import "./Notification.scss";
import { stringToObjectVar } from "../../../utils/utils";

type Props = {
  notif: Notification;
  handleNotificationClick: (notif: Notification) => void;
};

export const Notification: React.FunctionComponent<Props> = ({
  notif,
  handleNotificationClick,
}) => {
  const splittedDescription = notif.description.split("#");
  const translation = splittedDescription[0];
  const translationVar =
    splittedDescription.length > 1 ? splittedDescription[1] : null;

  return (
    <>
      <MenuItem
        onClick={() => handleNotificationClick(notif)}
        className={`notification ${notif.isActive ? "active" : "inactive"}`}
      >
        <div className="notification-line">
          <div>
            <div>
              <div className={"notification__target-reference"}>
                {notif.targetReference}{" "}
              </div>
            </div>
            <Tooltip
              title={Locale.trans(
                translation,
                translationVar ? stringToObjectVar(translationVar) : undefined
              )}
            >
              <p className={"notification__description"}>
                {Locale.trans(
                  translation,
                  translationVar ? stringToObjectVar(translationVar) : undefined
                )}
              </p>
            </Tooltip>
          </div>
          <div className={"notification__date"}>
            <div>
              {moment(notif.date).format(
                Locale.trans("notification.date_format")
              )}
            </div>
          </div>
        </div>
      </MenuItem>
    </>
  );
};
export default Notification;
