import * as React from "react";
import { useEffect, useState } from "react";
import { useField } from "react-form";
import { TextField as BaseTextField } from "@material-ui/core";
import { TextAlignProperty } from "csstype";
import "./TextField.scss";
import { useDebounce } from "../../hooks/useDebounce";

type FieldsProps = {
  className?: string;
  inputStyle?: string;
  field: string;
  label?: string;
  required?: boolean;
  validate?: (val: any) => string | false | undefined;
  multiline?: boolean;
  rows?: number;
  fullWidth?: boolean;
  disabled?: boolean;
  maxLength?: number;
  minLength?: number;
  InputProps?: any;
  setValue?: (arg: string) => void;
  value?: string;
  autocomplete?: "off" | "chrome-off";
  onBlur?: () => void;
  startingValue?: string;
  align?: TextAlignProperty;
  pattern?: string;
  variant?: "standard" | "outlined";
  helperText?: string;
  type?: string;
  shrink?: boolean;
  useDebouncedValue?: boolean;
};

export const TextField: React.FunctionComponent<FieldsProps> = ({
  autocomplete,
  align,
  startingValue,
  onBlur,
  shrink,
  fullWidth,
  value,
  minLength,
  setValue,
  InputProps,
  maxLength,
  disabled,
  className,
  inputStyle,
  required,
  field,
  validate,
  label,
  multiline,
  rows,
  variant,
  pattern,
  helperText,
  type,
  useDebouncedValue,
}) => {
  const {
    meta: { error },
    getInputProps,
    value: fieldValue,
    setValue: setFieldValue,
  } = useField(field, {
    validate: validate,
    defaultValue: value || startingValue || "",
  });

  const [debounceStateValue, setDebounceStateValue] = useState<string>(
    fieldValue
  );
  const debouncedValue = useDebounce(debounceStateValue, 500);

  useEffect(() => {
    useDebouncedValue && setFieldValue(debouncedValue);
  }, [debouncedValue]);

  useEffect(() => {
    if (value !== undefined && value !== fieldValue) {
      useDebouncedValue ? setDebounceStateValue(value) : setFieldValue(value);
    }
  }, [fieldValue, value, setDebounceStateValue, useDebouncedValue]);

  if (rows === undefined && multiline === true) {
    rows = 3;
  }

  const handleChange = (e: any) => {
    if (value !== undefined) {
      if (fieldValue !== value) {
        useDebouncedValue ? setDebounceStateValue(value) : setFieldValue(value);
      }
    } else {
      useDebouncedValue
        ? setDebounceStateValue(e.target.value)
        : setFieldValue(e.target.value);
    }

    if (setValue) {
      setValue(e.target.value);
    }
  };

  return (
    <BaseTextField
      {...getInputProps()}
      className={className + " text-field"}
      error={!!error}
      value={useDebouncedValue ? debounceStateValue : fieldValue}
      helperText={error || helperText}
      id={field}
      label={label}
      variant={variant ? variant : "outlined"}
      required={required}
      onBlur={onBlur}
      type={type}
      fullWidth={fullWidth}
      InputLabelProps={{
        shrink: shrink,
      }}
      InputProps={{
        ...InputProps,
        classes: {
          input: "text-field-input " + inputStyle,
        },
      }}
      inputProps={{
        maxLength: maxLength,
        style: { textAlign: align ? align : "left" },
        minLength: minLength,
        pattern: pattern,
      }}
      multiline={multiline}
      disabled={disabled}
      rows={rows}
      onChange={handleChange}
      autoComplete={autocomplete}
    />
  );
};
export default TextField;
